<template>
  <div class="my-accordion" :class="{ 'my-accordion--open': is_open }">
    <div @click="is_open = !is_open" class="my-accordion__header">
      <div class="my-accordion__title">{{ title }}</div>
      <div class="my-accordion__toggler"></div>
    </div>
    <transition name="slide">
      <div v-if="is_open" class="my-accordion__body">
        <div class="my-accordion__content">
          <slot />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'AppAccordion',
  props: {
    title: {
      type: String,
      default: ''
    },
    open: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      is_open: this.open
    }
  }
}
</script>

<style lang="sass" scoped>
@import "@/assets/common/index.scss"

.my-accordion
  margin-bottom: 15px
  border-radius: 10px
  background-color: #f8fdff

.my-accordion--open
  .my-accordion__title
    color: $color-theme

  .my-accordion__toggler
    &:before,
    &:after
      background-color: $color-theme

  .my-accordion__toggler
    transform: rotate(45deg)

.my-accordion__header
  display: flex
  align-items: center
  justify-content: space-between
  padding: 20px
  cursor: pointer

  &:hover
    .my-accordion__title
      color: $color-theme

    .my-accordion__toggler
      &:before,
      &:after
        background-color: $color-theme

.my-accordion__title
  font-size: 20px
  font-weight: 600
  transition: color 0.3s

.my-accordion__content
  padding: 0 20px 20px
  transition: padding-bottom 0.3s 0.3s

.my-accordion__toggler
  position: relative
  width: 16px
  height: 16px
  transition: transform 0.3s
  cursor: pointer

  &:before,
  &:after
    content: ''
    position: absolute
    top: 7px
    left: 0
    width: 100%
    height: 2px
    background-color: #000
    transition: background-color 0.3s

  &:before
    transform: rotate(90deg)

.slide-enter-active
  transition-duration: 0.3s
  transition-timing-function: ease-in

.slide-leave-active
  transition-duration: 0.3s
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1)

.slide-enter-to, .slide-leave
  max-height: 300px
  overflow: hidden

.slide-enter, .slide-leave-to
  overflow: hidden
  max-height: 0

</style>