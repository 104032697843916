import axios from '@/http/axios/axios'

export default {
  namespaced: true,
  actions: {
    GET_CITIZENSHIP() {
      return axios.get('dict/citizenship/')
    },
    GET_EDU_LEVEL() {
      return axios.get('dict/edu-level/')
    },
    GET_LANG() {
      return axios.get('dict/languages/')
    },
    GET_LANG_LEVEL() {
      return axios.get('dict/languages-level/')
    },
    GET_DOC_TYPE() {
      return axios.get('dict/doc-type/')
    },
    GET_FIELD_ACTIVITY() {
      return axios.get('dict/field-activity/')
    },
    GET_USER_STATUS() {
      return axios.get('dict/user-status/')
    },
    GET_EVENT_TYPE() {
      return axios.get('dict/events-type/')
    },
    GET_EVENT_FORMAT() {
      return axios.get('dict/events-part-form/')
    },
    GET_EVENT_APP_STATUS() {
      return axios.get('dict/events-applications-status/')
    },
    GET_EVENT_FIELDS() {
      return axios.get('dict/events-applications-fields/')
    },
    GET_BUILDER_TEMPLATES() {
      return axios.get('dict/events-shifts-templates/')
    },
  }
}
