<template>
  <vue-clip
      ref="vc"
      :options="options"
      :on-added-file="addedFile"
      :on-complete="completeFile"
      :on-sending="onSending"
      :on-removed-file="removedFile"
      class="custom-uploader custom-uploader--multi"
  >
    <template slot="clip-uploader-body">
      <div v-for="file in files" :key="file.id" class="custom-uploader__file">
        <div class="custom-uploader__body">
          <div class="custom-uploader__part">
            <a
                :href="file.file || file.customAttributes.link"
                target="_blank"
                class="custom-uploader__filename"
            >
              {{ file.file_name || file.name }}
            </a>
          </div>
          <div class="custom-uploader__part">
            <div
                v-if="file.status !== 'error' && file.status !== 'success' && file.progress"
                class="custom-uploader__progress"
            >
              {{ Math.round(file.progress) }}%
            </div>
            <button
                type="button"
                class="custom-uploader__delete"
                @click="removedFile(file)"
            >
              <!--              <img src="@/assets/img/file/delete.svg" alt="delete"/>-->
              Удалить
            </button>
          </div>
        </div>
        <div
            class="custom-uploader__line"
            v-if="file.status !== 'error' && file.status !== 'success' && file.progress"
        >
              <span
                  class="custom-uploader__indicator"
                  :style="{ width: file.progress + '%' }"
              ></span>
        </div>
      </div>
      <div class="custom-uploader__error">{{ message }}</div>
    </template>
    <template slot="clip-uploader-action" slot-scope="params">
      <div
          class="custom-uploader__action"
          :class="{ 'uploader__action--dragging': params.dragging }"
      >
        <div class="custom-uploader__message dz-message">
          <div
              class="custom-uploader__drop"
              v-show="files.length < $props.limit"
          >
            <p>Перетащите файлы или выберите на компьютере</p>
            <div class="custom-uploader__icon">
              <img src="@/assets/img/file/clip.svg" alt="Загрузить файлы">
              <span>Выбрать файл</span>
            </div>
            <small>Вы можете загрузить неограниченное количество файлов</small>
          </div>
        </div>
      </div>
      <div v-show="files.length < $props.limit" class="custom-uploader__extensions">
        Допустимые форматы файла: jpg, png, pdf. Допустимый размер файла - 25 МБ.
      </div>
    </template>
    <!--    <template slot="clip-uploader-action">-->
    <!--      <div>-->
    <!--        <div v-show="files.length < $props.limit" class="custom-uploader__message dz-message">-->
    <!--          <img src="@/assets/img/file/staple.svg" alt=""/>-->
    <!--          <span>Прикрепить файл</span>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </template>-->
  </vue-clip>
</template>

<script>
import { getAccessToken } from 'axios-jwt'

export default {
  name: 'AppMultiUploader',
  props: {
    files_prop: Array,
    extensions: {
      type: Array,
      default: () => ['application/pdf', 'image/*']
    },
    limit: Number
  },
  model: {
    prop: 'files_prop',
    event: 'onAddedFile'
  },
  data() {
    return {
      options: {
        url: `${process.env.VUE_APP_API_URL}files/`,
        // maxFiles: {
        //   limit: this.$props.limit,
        //   message: `Вы не можете загружать больше ${this.$props.limit} файлов`
        // },
        acceptedFiles: {
          extensions: this.$props.extensions,
          message: 'Неподходящий формат файла'
        },
        maxFilesize: {
          limit: 25,
          message: 'Размер файла превышает допустимый'
        },
        uploadMultiple: false,
        parallelUploads: this.$props.limit,
      },
      message: '',
      progress: 0,
      files: [],
    }
  },
  watch: {
    files_prop() {
      if (this.$props.files_prop.length) {
        this.files = this.$props.files_prop
      }
    }
  },
  methods: {
    addedFile(file) {
      this.files.push(file)
    },
    onSending(file, xhr) {
      const token = getAccessToken()
      xhr.setRequestHeader('Authorization', `Bearer ${token}`)
    },
    completeFile(file, status, xhr) {
      if (file.status === 'error') {
        this.message = file.errorMessage
        this.removedFile(file)
        return false
      }
      const response = JSON.parse(xhr.responseText)
      file.addAttribute('link', response.file)
      this.files.push(response)
      this.files = this.files.filter(item => !item.size)
      this.$emit('onAddedFile', this.files)
      this.message = ''
    },
    removedFile(file) {
      this.$refs.vc.files.splice(this.$refs.vc.files.indexOf(file), 1)
      this.files.splice(this.files.indexOf(file), 1)
      this.$emit('onAddedFile', this.files)
    },
  }
}
</script>
