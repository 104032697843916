import axios from '@/http/axios/axios'

export default {
  namespaced: true,
  actions: {
    GET_ADDRESS(state, data) {
      return axios.get(`address/?q=${data.search}&level=7,8,65,9`)
    },
    GET_ADDRESS_CITY(state, data) {
      return axios.get(`address/?q=${data.search}&level=4,6`)
    },
    GET_ADDRESS_REGION(state, data) {
      return axios.get(`address/?q=${data.search}&level=1`)
    },
    GET_FMS(state, data) {
      return axios.get(`find-fms/?q=${data.search}`)
    },
    GET_EDU_ORG(state, data) {
      return axios.get(`find-edu-org/?q=${data.search}`)
    },
    GET_ORG(state, data) {
      return axios.get(`find-org/?q=${data.search}`)
    },
  }
}
