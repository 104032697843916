import axios from '@/http/axios/axios'

export default {
  namespaced: true,
  actions: {
    GET_DATA(state, payload) {
      return axios.get(`me/team/?page_size=${payload.limit}&page=${payload.page}&is_head=${payload.is_head}`)
    },
    POST_DATA({ commit }, payload) {
      return axios.post('me/team/', payload)
    },
    GET_INVITATIONS(state, payload) {
      return axios.get(`me/team-invites/?page_size=${payload.limit}&page=${payload.page}`)
    },
    POST_ACCEPT({ commit }, payload) {
      return axios.post('me/team-invites/accept/', payload)
    },
    POST_REJECT({ commit }, payload) {
      return axios.post('me/team-invites/reject/', payload)
    },
    GET_INVITES(state, payload) {
      return axios.get(`me/team-out-invites/?page_size=${payload.limit}&page=${payload.page}`)
    },
  },
}
