<template>
  <button
      class="btn"
      @click="onClick"
      :disabled="disabled"
      :type="type"
      :class="[themes[theme], sizes[size], {'btn--beauty': disabled}, {'btn--preload': preload}]"
  >
    <slot v-if="!preload" />
    <img v-if="preload" src="@/assets/img/preloader.svg" alt="" class="btn__preloader" />
  </button>
</template>

<script>
export default {
  name: 'AppButton',
  data() {
    return {
      themes: {
        primary: 'btn--primary',
        transparent: 'btn--transparent',
        warning: 'btn--warning',
        accept: 'btn--accept',
        error: 'btn--error',
      },
      sizes: {
        normal: '',
        small: 'btn--sm',
        link: 'btn--link',
        stretch: 'btn--stretch',
      },
      preload: false,
    }
  },
  props: {
    theme: {
      type: String,
      default: 'primary'
    },
    size: {
      type: String,
      default: 'normal'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'submit'
    },
  },
  methods: {
    onClick(e) {
      if (this.disabled) return
      this.$emit('click', e)
    }
  }
}
</script>

<style lang="sass">
@import "@/assets/common/index.scss"

.btn
  display: flex
  align-items: center
  justify-content: center
  gap: 10px
  min-width: 190px
  min-height: 55px
  padding: 10px 35px
  border: 2px solid #fff
  border-radius: 15px
  font-size: 16px
  font-weight: 600
  line-height: 1
  text-align: center
  letter-spacing: 0.03em
  text-decoration: none
  transition: background-color 0.3s, border-color 0.3s, color 0.3s
  cursor: pointer
  user-select: none

  +max-w($mobile_sm)
    min-width: 120px
    min-height: 40px
    padding: 8px 15px
    border-radius: 10px
    font-size: 14px

  img, svg
    flex-shrink: 0

.btn--preload
  pointer-events: none

//span, img
//  display: none
//
//.btn__preloader
//  display: block

.btn--primary
  border-color: $color-theme
  color: #fff
  background-color: $color-theme

  +max-w($mobile_sm)
    width: 100%

  &:hover
    border-color: #49b1f5
    background-color: #49b1f5

  &:focus,
  &:active
    background-color: #177fd3

.btn--transparent
  border-color: $color-theme
  color: $color-theme

  +max-w($mobile_sm)
    width: 100%

  &:hover
    border-color: #49b1f5
    color: #49b1f5

  &:focus,
  &:active
    color: #177fd3
    border-color: #177fd3

.btn--warning
  border-color: #ff9416
  color: #fff
  background-color: #ff9416

  &:hover
    border-color: #fa9a3b
    background-color: #fa9a3b

  &:focus,
  &:active
    border-color: #fa9a3b
    background-color: #fa9a3b

.btn--accept
  border-color: #38bf4e
  color: #fff
  background-color: #38bf4e

  &:hover
    border-color: #46d45d
    background-color: #46d45d

  &:focus,
  &:active
    border-color: #46d45d
    background-color: #46d45d

.btn--error
  border-color: $color-error
  color: #fff
  background-color: $color-error

  &:hover
    border-color: rgba($color-error, 0.9)
    background-color: rgba($color-error, 0.9)

  &:focus,
  &:active
    border-color: rgba($color-error, 0.9)
    background-color: rgba($color-error, 0.9)

.btn--sm
  min-width: 150px
  min-height: 45px
  border-radius: 10px
  font-size: 15px

.btn--stretch
  width: 100%

.btn--beauty
  opacity: 0.5
  pointer-events: none

.btn--link
  min-width: auto
  min-height: auto
  padding: 0
  border: none
  background-color: transparent
  color: $color-theme
  font-size: 15px
  transition: opacity 0.3s

  +max-w($mobile_sm)
    font-size: 14px

  &:hover
    background-color: transparent
    opacity: 0.7

  &:focus,
  &:active
    background-color: transparent

  &.btn--error
    color: #ea5467

.btn__preloader
  width: 20px
  height: 20px
</style>
