<template>
  <div class="envelope">
    <div class="profile">
      <div class="profile__wrapper">
        <div class="profile__content">
          <div class="profile__photo">
            <img v-if="$store.state.meProfile.crop_url" :src="$store.state.meProfile.crop_url" alt="">
            <img v-else src="@/assets/img/profile/profile-photo-def.svg" alt="">
          </div>
          <div class="profile__info">
            <div class="profile__name" v-if="$store.state.meProfile.first_name">{{ $store.state.meProfile.last_name }} {{ $store.state.meProfile.first_name }} {{ $store.state.meProfile.patronymic }}</div>
            <div class="profile__name" v-else>Тут должно быть ваше имя</div>
            <a :href="$router.resolve({ name: 'profile-main-edit' }).href" class="profile__edit">
              <img src="@/assets/img/profile/profile-edit.svg" alt="">
              Редактировать
            </a>
          </div>
        </div>
      </div>
      <div class="profile__contacts">
        <div class="profile-contacts">
          <div class="profile-contacts__item">
            <img src="@/assets/img/profile/dob-icon.svg" alt="" class="profile-contacts__icon">
            <div class="profile-contacts__info">
              <div class="profile-contact__text profile-contact__text--strong">Дата рождения</div>
              <div class="profile-contact__text">{{ $store.state.meProfile.dob }}</div>
            </div>
          </div>
          <div class="profile-contacts__item">
            <img src="@/assets/img/profile/phone-icon.svg" alt="" class="profile-contacts__icon">
            <div class="profile-contacts__info">
              <div class="profile-contact__text profile-contact__text--strong">Телефон</div>
              <div class="profile-contact__text">{{ $store.state.meProfile.phone }}</div>
            </div>
          </div>
          <div class="profile-contacts__item">
            <img src="@/assets/img/profile/email-icon.svg" alt="" class="profile-contacts__icon">
            <div class="profile-contacts__info">
              <div class="profile-contact__text profile-contact__text--strong">Электронная почта</div>
              <div class="profile-contact__text">
                {{ $store.state.meProfile.email }}
                <img v-if="$store.state.meProfile.email_is_verified" src="@/assets/img/check-green.svg" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="!$store.state.meProfile.email_is_verified" class="profile__comment">
        <div class="notice  notice--yellow">
          <div class="notice__title">
            <img src="@/assets/img/warning-icon.svg" alt="">
            <span>Подтвердите адрес электронной почты</span>
          </div>
          <div class="notice__text">
            <p>На указанный при регистрации адрес электронной почты было отправлено письмо. В нем находится ссылка, по которой нужно перейти для завершения регистрации и подтверждения электронного адреса.</p>
            <p>Если письма нет в папке «Входящие», проверьте папку «Спам» и выполните поиск по отправителю robot@ruy.ru</p>
            <p>В случае, если письмо не удалось найти, нажмите "Отправить повторно", чтобы отправить письмо повторно.</p>
          </div>
          <div class="notice__button">
            <AppButton @click="onConfirmEmail" type="button" size="small" theme="warning">Отправить повторно</AppButton>
          </div>
        </div>
      </div>

    </div>
    <div class="content content--home">
      <div class="content__tabs">
        <div class="tabs-list">
          <ul class="tabs-list__body">
            <li class="tabs-list__item">
              <router-link :to="{ name: 'profile-personal' }" exact-active-class="tabs-list__link--active" class="tabs-list__link">Личные данные</router-link>
            </li>
            <li class="tabs-list__item">
              <router-link :to="{ name: 'profile-education' }" exact-active-class="tabs-list__link--active" class="tabs-list__link">Образование</router-link>
            </li>
            <li class="tabs-list__item">
              <router-link :to="{ name: 'profile-documents' }" exact-active-class="tabs-list__link--active" class="tabs-list__link">Документы</router-link>
            </li>
            <li class="tabs-list__item">
              <router-link :to="{ name: 'profile-career' }" exact-active-class="tabs-list__link--active" class="tabs-list__link">Карьера</router-link>
            </li>
            <li class="tabs-list__item">
              <router-link :to="{ name: 'profile-other' }" exact-active-class="tabs-list__link--active" class="tabs-list__link">Прочее</router-link>
            </li>
          </ul>
        </div>
      </div>
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Profile',
  methods: {
    onConfirmEmail() {
      this.$store.dispatch('profile/GET_CONFIRM_EMAIL')
        .then(() => {
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: 'Ссылка для подтверждения отправлена на Ваш email'
          })
        })
        .catch(error => {
          for (const key in error.response.data) {
            if (typeof error.response.data[key] === 'string') {
              this.$notify({
                type: 'error',
                text: error.response.data[key]
              })
            } else {
              this.$notify({
                type: 'error',
                text: error.response.data[key][0]
              })
            }
          }
        })
    },
  },
}
</script>
<style lang="sass">
.fade-enter-active, .fade-leave-active
  transition: opacity 0.5s

.fade-enter, .fade-leave-to
  opacity: 0
</style>
