<template>
  <div class="envelope">
    <div class="envelope__bg">
      <img src="@/assets/img/team/header-bg.svg" alt="">
      <router-link :to="{ name: 'team-create' }" class="button button--mini envelope__button">Создать команду</router-link>
    </div>
    <div class="content">
      <div class="content__mobile">
        <router-link :to="{ name: 'team-create' }" class="button button--mini">Создать команду</router-link>
      </div>
      <div class="content__tabs">
        <div class="tabs-list">
          <ul class="tabs-list__body">
            <li class="tabs-list__item">
              <router-link :to="{ name: 'my-teams' }" exact-active-class="tabs-list__link--active"
                           class="tabs-list__link">Мои команды
              </router-link>
            </li>
            <li class="tabs-list__item">
              <router-link :to="{ name: 'my-invitations' }" exact-active-class="tabs-list__link--active"
                           class="tabs-list__link">Приглашения в команду
              </router-link>
            </li>
            <li class="tabs-list__item">
              <router-link :to="{ name: 'my-invites' }" exact-active-class="tabs-list__link--active"
                           class="tabs-list__link">Мои заявки на вступление
              </router-link>
            </li>
          </ul>
        </div>
      </div>
      <transition name="fade" mode="out-in">
        <router-view class="content__body"/>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TeamsLayout',
}
</script>
<style lang="sass">
.fade-enter-active, .fade-leave-active
  transition: opacity 0.5s

.fade-enter, .fade-leave-to
  opacity: 0
</style>
