<template>
  <div class="card">
    <slot name="default" />
  </div>
</template>

<script>
export default {
  name: 'AppCard'
}
</script>

<style scoped lang="sass">
.card
  position: relative
  padding: 30px
  background: #f8fdff
  box-shadow: 0 0 4px rgba(151, 151, 151, 0.25)
  border-radius: 10px
  text-decoration: none
  color: #323f4b
</style>
