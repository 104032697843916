import axios from '@/http/axios/axios'

export default {
  namespaced: true,
  actions: {
    GET_DATA(state, id) {
      return axios.get(`me/team/${id}/`)
    },
    PATCH_DATA(state, payload) {
      return axios.patch(`me/team/${payload.id}/`, payload)
    },
    GET_MEMBERS(state, payload) {
      return axios.get(`me/team/${payload.id}/member/?status=${payload.status}`)
    },
    POST_INVITE(state, payload) {
      return axios.post(`me/team/${payload.id}/member/invite/`, payload.form)
    },
    POST_LINK_INVITE(state, payload) {
      return axios.post('me/team/link-join/', payload)
    },
    POST_CHANGE_ADMIN(state, payload) {
      return axios.post(`me/team/${payload.teamId}/change-head/`, payload.data)
    },
    DELETE_MEMBER(state, payload) {
      return axios.delete(`me/team/${payload.teamId}/member/${payload.memberId}`)
    },
    POST_ACCEPT_INVITE(state, payload) {
      return axios.post(`me/team/${payload.teamId}/member/accept/`, payload.data)
    },
  }
}