import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import axios from '@/http/axios/axios'
import dict from './dict'
import search from './search'
import auth from './auth'
import profile from './profile'
import teams from './teams'
import team from './team'
import events from './events'
import mkpCards from './mkp-card'
import membership from './membership'
import admin from './admin'
import stat from './stat'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState()],
  namespaced: true,
  state: {
    sidebar: false,
    sidebarOpen: false,
    firstName: '',
    lastName: '',
    avatar: '',
    meProfile: {
      user_permissions: {
        is_admin: false
      }
    },
  },
  mutations: {
    sidebarToggle(state) {
      state.sidebar = !state.sidebar
    },
    sidebarMobileToggle(state) {
      state.sidebarOpen = !state.sidebarOpen
    },
    setFirstName (state, payload) {
      state.firstName = payload
    },
    setLastName (state, payload) {
      state.lastName = payload
    },
    setAvatar(state, payload) {
      state.avatar = payload
    },
    setProfile(state, payload) {
      state.meProfile = payload
    },
  },
  actions: {
    POST_FILE(state, payload) {
      return axios.post('files/', payload)
    },
    POST_FORGOT(state, data) {
      return axios.post('password_reset/', data)
    },
    CHECK_PASS_TOKEN(state, token) {
      return axios.post(`password_reset/check/${token}/`)
    },
    POST_CONFIRM(state, payload) {
      return axios.post(`password_reset/confirm/${payload.token}/`, payload.data)
    },
  },
  modules: {
    dict,
    search,
    auth,
    profile,
    teams,
    team,
    events,
    mkpCards,
    membership,
    admin,
    stat
  }
})
