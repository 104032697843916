import axios from '@/http/axios/axios'

export default {
  namespaced: true,
  actions: {
    GET_DATA(state, payload) {
      return axios.get(`admin/events/${payload.id}/shifts/${payload.dir}/`, payload.data)
    },
    POST_DATA(state, payload) {
      return axios.post(`admin/events/${payload.id}/shifts/`, payload.data)
    },
    PATCH_DATA(state, payload) {
      return axios.put(`admin/events/${payload.id}/shifts/${payload.dir}/`, payload.data)
    },
    CHECK_PROFILE(state, payload) {
      return axios.post(`me/events/${payload.id}/shifts/${payload.dir}/check-profile`, payload.data)
    },
  },
}
