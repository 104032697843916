import axios from '@/http/axios/axios'
import directions from './directions'
import applications from './applications'
import application from './application'

export default {
  namespaced: true,
  state: {
    event_name: ''
  },
  mutations: {
    setEventName(state, payload) {
      state.event_name = payload
    },
  },
  actions: {
    GET_DATA(state, id) {
      return axios.get(`admin/events/${id}/`)
    },
    GET_DATA_USER(state, id) {
      return axios.get(`me/events/${id}/`)
    },
    POST_DATA(state, payload) {
      return axios.post('admin/events/', payload)
    },
    GET_LIST(state, search) {
      return axios.get(`admin/events/?page_size=300&query=${search}`)
    },
    PATCH_DATA(state, payload) {
      return axios.patch(`admin/events/${payload.id}/`, payload.data)
    },
    GET_LIST_USER(state, payload) {
      return axios.get(`me/events/?page_size=300&query=${payload.search}&only_region=${payload.only_region}`)
    },
  },
  modules: {
    directions,
    applications,
    application,
  },
}
