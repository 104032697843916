import axios from '@/http/axios/axios'

export default {
  namespaced: true,
  actions: {
    POST_LINK_CARD(state, number) {
      return axios.post('integration/corp/link-ticket/', number)
    },
    POST_CHECK_CARD() {
      return axios.post('integration/corp/my-ticket/')
    },
    GET_GOOGLE_LINK() {
      return axios.get('integration/corp/link-google/')
    },
    GET_APPLE_LINK() {
      return axios.get('integration/corp/link-apple/')
    },
  }
}
