import Vue from 'vue'
import VueRouter from 'vue-router'
import ProfileIndex from '@/views/profile/Index'
import Profile from '@/views/profile/Profile'
import TeamsIndex from '@/views/teams/Index'
import Teams from '@/views/teams/Teams'
import MkpCards from '@/views/mkp-card/Index'
import Membership from '@/views/membership/Index'
import MyAppsIndex from '@/views/my-apps/Index'
import store from '@/store'
import { isLoggedIn } from 'axios-jwt'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: { name: 'profile-personal' }
  },
  {
    path: '/profile',
    component: ProfileIndex,
    redirect: { name: 'profile-personal' },
    children: [
      {
        path: 'edit',
        name: 'profile-main-edit',
        component: () => import('@/views/profile/Edit.vue'),
        meta: {
          title: 'Редактирование основных данных | АИС «Клуб РСМ»'
        },
      },
      {
        path: 'personal/address-edit',
        name: 'profile-personal-address-edit',
        component: () => import('@/views/profile/personal/AddressEdit.vue'),
        meta: {
          title: 'Редактирование адресов | АИС «Клуб РСМ»'
        },
      },
      {
        path: 'personal/passport-edit',
        name: 'profile-personal-passport-edit',
        component: () => import('@/views/profile/personal/PassportEdit.vue'),
        meta: {
          title: 'Редактирование паспортных данных | АИС «Клуб РСМ»'
        },
      },
      {
        path: 'personal/socials-edit',
        name: 'profile-personal-socials-edit',
        component: () => import('@/views/profile/personal/SocialsEdit.vue'),
        meta: {
          title: 'Редактирование социальных сетей | АИС «Клуб РСМ»'
        },
      },
      {
        path: 'education/organization',
        name: 'profile-organization-create',
        component: () => import('@/views/profile/education/OrganizationCreate.vue'),
        meta: {
          title: 'Добавление образования | АИС «Клуб РСМ»'
        },
      },
      {
        path: 'education/organization/:id',
        name: 'profile-organization-edit',
        component: () => import('@/views/profile/education/OrganizationEdit.vue'),
        meta: {
          title: 'Редактирование образования | АИС «Клуб РСМ»'
        },
      },
      {
        path: 'education/language',
        name: 'profile-language-create',
        component: () => import('@/views/profile/education/LanguageCreate.vue'),
        meta: {
          title: 'Добавление владения языком | АИС «Клуб РСМ»'
        },
      },
      {
        path: 'education/language/:id',
        name: 'profile-language-edit',
        component: () => import('@/views/profile/education/LanguageEdit.vue'),
        meta: {
          title: 'Редактирование владения языком | АИС «Клуб РСМ»'
        },
      },
      {
        path: 'documents/create',
        name: 'profile-docs-create',
        component: () => import('@/views/profile/documents/Create.vue'),
        meta: {
          title: 'Добавление документа | АИС «Клуб РСМ»'
        },
      },
      {
        path: 'documents/:id',
        name: 'profile-docs-edit',
        component: () => import('@/views/profile/documents/Edit.vue'),
        meta: {
          title: 'Редактирование документа | АИС «Клуб РСМ»'
        },
      },
      {
        path: 'career/create',
        name: 'profile-career-create',
        component: () => import('@/views/profile/career/Create.vue'),
        meta: {
          title: 'Добавление места работы | АИС «Клуб РСМ»'
        },
      },
      {
        path: 'career/:id',
        name: 'profile-career-edit',
        component: () => import('@/views/profile/career/Edit.vue'),
        meta: {
          title: 'Редактирование места работы | АИС «Клуб РСМ»'
        },
      },
      {
        path: 'other/edit',
        name: 'profile-other-edit',
        component: () => import('@/views/profile/other/Edit.vue'),
        meta: {
          title: 'Редактирование прочей информации | АИС «Клуб РСМ»'
        },
      },
      {
        path: '',
        name: 'profile',
        component: Profile,
        redirect: { name: 'profile-personal' },
        children: [
          {
            path: 'personal',
            name: 'profile-personal',
            component: () => import('@/views/profile/personal/List.vue'),
            meta: {
              title: 'Профиль | АИС «Клуб РСМ»'
            },
          },
          {
            path: 'education',
            name: 'profile-education',
            component: () => import('@/views/profile/education/List.vue'),
            meta: {
              title: 'Образование | АИС «Клуб РСМ»'
            },
          },
          {
            path: 'documents',
            name: 'profile-documents',
            component: () => import('@/views/profile/documents/List.vue'),
            meta: {
              title: 'Документы | АИС «Клуб РСМ»'
            },
          },
          {
            path: 'career',
            name: 'profile-career',
            component: () => import('@/views/profile/career/List.vue'),
            meta: {
              title: 'Карьера | АИС «Клуб РСМ»'
            },
          },
          {
            path: 'interests',
            name: 'profile-interests',
            component: () => import('@/views/profile/interests/Detail.vue'),
            meta: {
              title: 'Интересы | АИС «Клуб РСМ»'
            },
          },
          {
            path: 'other',
            name: 'profile-other',
            component: () => import('@/views/profile/other/List.vue'),
            meta: {
              title: 'Прочее | АИС «Клуб РСМ»'
            },
          },
        ]
      },
    ]
  },
  {
    path: '/teams',
    component: TeamsIndex,
    redirect: { name: 'my-teams' },
    children: [
      {
        path: 'create',
        name: 'team-create',
        component: () => import('@/views/teams/Create.vue'),
        meta: {
          title: 'Мои команды | АИС «Клуб РСМ»'
        },
      },
      {
        path: '',
        name: 'teams',
        component: Teams,
        redirect: { name: 'my-teams' },
        children: [
          {
            path: 'my',
            name: 'my-teams',
            component: () => import('@/views/teams/My.vue'),
            meta: {
              title: 'Мои команды | АИС «Клуб РСМ»'
            },
          },
          {
            path: 'invitations',
            name: 'my-invitations',
            component: () => import('@/views/teams/Invitations.vue'),
            meta: {
              title: 'Мои приглашения в команду | АИС «Клуб РСМ»'
            },
          },
          {
            path: 'invites',
            name: 'my-invites',
            component: () => import('@/views/teams/Invites.vue'),
            meta: {
              title: 'Мои заявки в команду | АИС «Клуб РСМ»'
            },
          },
        ]
      },
      {
        path: ':id',
        component: () => import('@/views/teams/team/Index'),
        children: [
          {
            path: '',
            name: 'team-detail',
            component: () => import('@/views/teams/team/Detail'),
            meta: {
              title: 'Моя команда | АИС «Клуб РСМ»'
            },
          },
          {
            path: 'invites',
            name: 'team-invites',
            component: () => import('@/views/teams/team/Invites'),
            meta: {
              title: 'Заявки команды | АИС «Клуб РСМ»'
            },
          },
          {
            path: 'invitations',
            name: 'team-invitations',
            component: () => import('@/views/teams/team/Invitations'),
            meta: {
              title: 'Приглашения команды | АИС «Клуб РСМ»'
            },
          },
        ]
      },
      {
        path: ':id/edit',
        name: 'team-edit',
        component: () => import('@/views/teams/team/Edit'),
        meta: {
          title: 'Редактирование команды | АИС «Клуб РСМ»'
        },
      },
    ],
  },
  {
    path: '/events',
    component: () => import('@/views/events/Index'),
    redirect: { name: 'events' },
    children: [
      {
        path: '',
        name: 'events',
        redirect: { name: 'events-list' },
        component: () => import('@/views/events/Layout'),
        children: [
          {
            path: '',
            name: 'events-list',
            component: () => import('@/views/events/user/List'),
            meta: {
              title: 'Список мероприятий | АИС «Клуб РСМ»'
            },
          },
          {
            path: 'manage',
            name: 'events-manage',
            component: () => import('@/views/events/manage/List'),
            meta: {
              title: 'Список мероприятий | АИС «Клуб РСМ»'
            },
          },
        ]
      },
      {
        path: ':id',
        name: 'event-user-index',
        component: () => import('@/views/events/user/Index'),
        redirect: { name: 'event-user-detail' },
        children: [
          {
            path: '',
            name: 'event-user-detail',
            component: () => import('@/views/events/user/event/Detail'),
            meta: {
              title: 'Страница мероприятия | АИС «Клуб РСМ»'
            },
          },
          {
            path: ':dir/create',
            name: 'application-create',
            component: () => import('@/views/events/user/event/applications/Create'),
            meta: {
              title: 'Создание заявки | АИС «Клуб РСМ»'
            },
          },
        ]
      },
      {
        path: 'manage/',
        component: () => import('@/views/events/manage/Index'),
        children: [
          {
            path: 'create',
            name: 'event-create',
            component: () => import('@/views/events/manage/event/Create'),
            meta: {
              title: 'Создание мероприятия | АИС «Клуб РСМ»'
            },
          },
          {
            path: ':id',
            name: 'event-manage-index',
            component: () => import('@/views/events/manage/event/Index'),
            redirect: { name: 'event-detail' },
            children: [
              {
                path: '',
                name: 'event-detail',
                component: () => import('@/views/events/manage/event/Detail'),
                meta: {
                  title: `Управление мероприятием - ${store.state.events.event_name} | АИС «Клуб РСМ»`
                },
              },
              {
                path: 'edit',
                name: 'event-edit',
                component: () => import('@/views/events/manage/event/Edit'),
                meta: {
                  title: 'Редактирование мероприятия | АИС «Клуб РСМ»'
                },
              },
              {
                path: 'directions',
                component: () => import('@/views/events/manage/event/directions/Index'),
                redirect: { name: 'event-detail' },
                children: [
                  {
                    path: 'create',
                    name: 'direction-create',
                    component: () => import('@/views/events/manage/event/directions/Create'),
                    meta: {
                      title: 'Добавление направления | АИС «Клуб РСМ»'
                    },
                  },
                  {
                    path: ':dir',
                    component: () => import('@/views/events/manage/event/directions/Layout'),
                    children: [
                      {
                        path: '',
                        name: 'direction-edit',
                        component: () => import('@/views/events/manage/event/directions/Edit'),
                        meta: {
                          title: 'Редактирование заявки | АИС «Клуб РСМ»'
                        },
                      },
                      {
                        path: 'applications',
                        component: () => import('@/views/events/manage/event/applications/Index'),
                        children: [
                          {
                            path: '',
                            name: 'applications-list',
                            component: () => import('@/views/events/manage/event/applications/List'),
                            meta: {
                              title: 'Список заявок | АИС «Клуб РСМ»'
                            },
                          },
                          {
                            path: ':app',
                            component: () => import('@/views/events/manage/event/application/Index'),
                            children: [
                              {
                                path: '',
                                name: 'application-detail',
                                component: () => import('@/views/events/manage/event/application/Detail'),
                                meta: {
                                  title: 'Данные заявки | АИС «Клуб РСМ»'
                                }
                              },
                              {
                                path: 'list',
                                name: 'application-list',
                                component: () => import('@/views/events/manage/event/application/List'),
                                meta: {
                                  title: 'История заявок | АИС «Клуб РСМ»'
                                },
                              },
                            ]
                          },
                        ]
                      },
                    ]
                  },
                ]
              },
            ]
          },
        ]
      },
    ]
  },
  {
    path: '/my-apps',
    component: MyAppsIndex,
    children: [
      {
        path: '',
        name: 'my-apps',
        component: () => import('@/views/my-apps/List'),
        meta: {
          title: 'Мои заявки на мероприятие | АИС «Клуб РСМ»'
        },
      },
      {
        path: ':id',
        name: 'my-app-edit',
        component: () => import('@/views/my-apps/Edit'),
        meta: {
          title: 'Редактирование заявки на мероприятие | АИС «Клуб РСМ»'
        },
      }
    ]
  },
  // {
  //   path: '/mkp-card',
  //   name: 'mkp-card',
  //   redirect: { name: 'mkp-card-my' },
  //   component: MkpCards,
  //   children: [
  //     {
  //       path: 'my',
  //       name: 'mkp-card-my',
  //       component: () => import('@/views/mkp-card/Detail'),
  //       meta: {
  //         title: 'Моя карта МКП | АИС «Клуб РСМ»'
  //       },
  //     },
  //     {
  //       path: 'link',
  //       name: 'mkp-card-link',
  //       component: () => import('@/views/mkp-card/List'),
  //       meta: {
  //         title: 'Карты МКП | АИС «Клуб РСМ»'
  //       },
  //     },
  //     {
  //       path: 'mkp',
  //       name: 'mkp-card-main',
  //       component: () => import('@/views/mkp-card/InfoMkp'),
  //       meta: {
  //         title: 'Информация о карте МКП | АИС «Клуб РСМ»'
  //       },
  //     },
  //     {
  //       path: 'gazprom',
  //       name: 'mkp-card-gaz',
  //       component: () => import('@/views/mkp-card/InfoGaz'),
  //       meta: {
  //         title: 'Информация о карте Газпром | АИС «Клуб РСМ»'
  //       },
  //     },
  //     {
  //       path: 'create',
  //       name: 'mkp-card-create',
  //       component: () => import('@/views/mkp-card/Create'),
  //       meta: {
  //         title: 'Оформление карты Газпром | АИС «Клуб РСМ»'
  //       },
  //     },
  //   ]
  // },
  // {
  //   path: '/membership',
  //   name: 'membership',
  //   component: Membership,
  //   children: [
  //     {
  //       path: 'my',
  //       name: 'membership-my',
  //       component: () => import('@/views/membership/Detail'),
  //       meta: {
  //         title: 'Мой членский билет | АИС «Клуб РСМ»'
  //       },
  //     },
  //     {
  //       path: 'link',
  //       name: 'membership-link',
  //       component: () => import('@/views/membership/Link'),
  //       meta: {
  //         title: 'Привязать членский билет | АИС «Клуб РСМ»'
  //       },
  //     },
  //   ]
  // },
  {
    path: '/admin',
    component: () => import('@/views/admin/Index'),
    redirect: { name: 'admin-users-list' },
    children: [
      {
        path: 'users',
        component: () => import('@/views/admin/users/Index'),
        redirect:  { name: 'admin-users-list' },
        children: [
          {
            path: '',
            name: 'admin-users-list',
            component: () => import('@/views/admin/users/List'),
            meta: {
              title: 'Список всех пользователей | АИС «Клуб РСМ»'
            }
          },
          {
            path: ':id',
            name: 'admin-user-layout',
            component: () => import('@/views/admin/users/user/Layout'),
            redirect:  { name: 'admin-user-detail' },
            children: [
              {
                path: '',
                name: 'admin-user-detail',
                component: () => import('@/views/admin/users/user/Detail'),
                meta: {
                  title: 'Профиль пользователя | АИС «Клуб РСМ»'
                }
              },
              {
                path: 'settings',
                name: 'admin-user-settings',
                component: () => import('@/views/admin/users/user/Settings'),
                meta: {
                  title: 'Настройки пользователя | АИС «Клуб РСМ»'
                }
              }
            ]
          }
        ]
      },
      {
        path: '/stat',
        name: 'stat',
        component: () => import('@/views/admin/Stat'),
        meta: {
          title: 'Статистика | АИС «Клуб РСМ»',
          requiresAuth: true,
        }
      },
      {
        path: '/dicts',
        name: 'dicts',
        component: () => import('@/views/admin/dicts/Index'),
        redirect: { name: 'fms' },
        children: [
          {
            path: 'fms',
            name: 'fms',
            component: () => import('@/views/admin/dicts/fms/Index'),
            redirect: { name: 'fms-list' },
            children: [
              {
                path: '',
                name: 'fms-list',
                component: () => import('@/views/admin/dicts/fms/List'),
                meta: {
                  title: 'Список ФМС | АИС «Клуб РСМ»',
                  requiresAuth: true,
                }
              },
              {
                path: 'create',
                name: 'fms-create',
                component: () => import('@/views/admin/dicts/fms/Create'),
                meta: {
                  title: 'Добавление ФМС | АИС «Клуб РСМ»',
                  requiresAuth: true,
                }
              },
              {
                path: ':id',
                name: 'fms-edit',
                component: () => import('@/views/admin/dicts/fms/Edit'),
                meta: {
                  title: 'Редактирование ФМС | АИС «Клуб РСМ»',
                  requiresAuth: true,
                }
              },
            ]
          },
          {
            path: 'organization',
            name: 'org',
            component: () => import('@/views/admin/dicts/org/Index'),
            redirect: { name: 'org-list' },
            children: [
              {
                path: '',
                name: 'org-list',
                component: () => import('@/views/admin/dicts/org/List'),
                meta: {
                  title: 'Список организаций | АИС «Клуб РСМ»',
                  requiresAuth: true,
                },
              },
              {
                path: 'create',
                name: 'org-create',
                component: () => import('@/views/admin/dicts/org/Create'),
                meta: {
                  title: 'Добавление организации | АИС «Клуб РСМ»',
                  requiresAuth: true,
                }
              },
              {
                path: ':id',
                name: 'org-edit',
                component: () => import('@/views/admin/dicts/org/Edit'),
                meta: {
                  title: 'Редактирование организации | АИС «Клуб РСМ»',
                  requiresAuth: true,
                }
              },
            ]
          },
        ]
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/auth/Login'),
    meta: {
      layout: 'welcome',
      title: 'Вход | АИС «Клуб РСМ»'
    },
  },
  {
    path: '/registration',
    name: 'registration',
    component: () => import('@/views/auth/Registration'),
    meta: {
      layout: 'public',
      title: 'Регистрация | АИС «Клуб РСМ»'
    },
  },
  {
    path: '/password-forgot',
    name: 'password-forgot',
    component: () => import('@/views/auth/PasswordForgot'),
    meta: {
      layout: 'welcome',
      title: 'Восстановление пароля | АИС «Клуб РСМ»'
    }
  },
  {
    path: '/password-confirm',
    name: 'password-confirm',
    component: () => import('@/views/auth/PasswordConfirm'),
    meta: {
      layout: 'welcome',
      title: 'Подтверждение пароля | АИС «Клуб РСМ»'
    }
  },
  {
    path: '/email-confirm',
    name: 'email-confirm',
    component: () => import('@/views/auth/EmailConfirm'),
    meta: {
      layout: 'welcome',
      title: 'Подтверждение адреса электронной почты | АИС «Клуб РСМ»'
    }
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('@/views/Privacy'),
    meta: {
      layout: 'public',
      title: 'Согласие на обработку персональных данных | АИС «Клуб РСМ»'
    }
  },
  {
    path: '/agreement',
    name: 'agreement',
    component: () => import('@/views/Agreement'),
    meta: {
      layout: 'public',
      title: 'Пользовательское соглашение | АИС «Клуб РСМ»'
    }
  },
  {
    path: '/404',
    name: 'error404',
    component: () => import('@/views/Error404'),
    meta: {
      layout: 'public',
      title: 'Страница не найдена | АИС «Клуб РСМ»'
    }
  },
  {
    path: '*',
    redirect: { name: 'error404' }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

router.afterEach(() => {
  const appLoading = document.getElementById('loading')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  const loggedIn = isLoggedIn()

  if (to.fullPath.includes('membership')) {
    store.dispatch('membership/POST_CHECK_CARD')
      .then(() => { next({ name: 'membership-my' }) })
      .catch(() => { next({ name: 'membership-link' }) })
  }

  if (
    (to.name === 'login' ||
    to.name === 'registration' ||
    to.name === 'password-forgot' ||
    to.name === 'password-confirm' ||
    to.name === 'privacy' ||
    to.name === 'agreement' ||
    to.name === '404') &&
    loggedIn
  ) {
    next({ name: 'profile' })
  } else {
    next()
  }
})

export default router
