import axios from 'axios'
import router from '@/router'
import { applyAuthTokenInterceptor, clearAuthTokens } from 'axios-jwt'

const baseURL = process.env.VUE_APP_API_URL
const axiosInstance = axios.create({ baseURL })

const requestRefresh = (refresh) => {
  return new Promise((resolve, reject) => {
    axios.post(`${baseURL}token/jwt/refresh/`, { refresh })
      .then(response => {
        resolve({
          accessToken: response.data.access,
          refreshToken: response.data.refresh
        })
      })
      .catch(error => {
        if (error.response.status === 401) {
          clearAuthTokens()
          localStorage.clear()
          router.push({ name: 'login' }).catch(() => {})
        }
        console.log('jwt refresh error:', error)
        reject(error)
      })
  })
}

axiosInstance.interceptors.response.use(undefined, function (err) {
  return new Promise(function () {
    if (err.response.status === 401) {
      clearAuthTokens()
      localStorage.clear()
      router.push({ name: 'login' }).catch(() => {})
    }
    throw err
  })
})

applyAuthTokenInterceptor(axiosInstance, { requestRefresh })

export default axiosInstance
