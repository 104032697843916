import Vue from 'vue'
import AppGrid from '@/components/my-components/AppGrid'
import AppInput from '@/components/my-components/AppInput'
import AppFormGroup from '@/components/my-components/AppFormGroup'
import AppButton from '@/components/my-components/AppButton'
import AppCells from '@/components/my-components/AppCells'
import AppRadio from '@/components/my-components/AppRadio'
import AppCheckbox from '@/components/my-components/AppCheckbox'
import AppCheckboxList from '@/components/my-components/AppCheckboxList'
import AppUploader from '@/components/my-components/AppUploader'
import AppMultiUploader from '@/components/my-components/AppMultiUploader'
import AppPhone from '@/components/my-components/AppPhone'
import AppTextarea from '@/components/my-components/AppTextarea'
import AppCropper from '@/components/my-components/AppCropper'
import AppTableHead from '@/components/my-components/AppTableHead'
import AppTooltip from '@/components/my-components/AppTooltip'
import AppFilters from '@/components/my-components/AppFilters'
import AppCard from '@/components/my-components/AppCard'
import AppSidebarRight from '@/components/my-components/AppSidebarRight'
import AppSwitcher from '@/components/my-components/AppSwitcher'
import AppAccordion from '@/components/my-components/AppAccordion'

Vue.component('AppGrid', AppGrid)
Vue.component('AppInput', AppInput)
Vue.component('AppFormGroup', AppFormGroup)
Vue.component('AppButton', AppButton)
Vue.component('AppCells', AppCells)
Vue.component('AppRadio', AppRadio)
Vue.component('AppCheckbox', AppCheckbox)
Vue.component('AppCheckboxList', AppCheckboxList)
Vue.component('AppUploader', AppUploader)
Vue.component('AppMultiUploader', AppMultiUploader)
Vue.component('AppPhone', AppPhone)
Vue.component('AppTextarea', AppTextarea)
Vue.component('AppCropper', AppCropper)
Vue.component('AppTableHead', AppTableHead)
Vue.component('AppTooltip', AppTooltip)
Vue.component('AppFilters', AppFilters)
Vue.component('AppCard', AppCard)
Vue.component('AppSidebarRight', AppSidebarRight)
Vue.component('AppSwitcher', AppSwitcher)
Vue.component('AppAccordion', AppAccordion)
