import axios from '@/http/axios/axios'
import personal from './personal'
import education from './education'
import documents from './documents'
import career from './career'
import other from './other'
import passport from './passport'

export default {
  namespaced: true,
  actions: {
    GET_DATA() {
      return axios.get('me/')
    },
    PATCH_DATA(state, payload) {
      return axios.patch('me/', payload)
    },
    GET_CONFIRM_EMAIL() {
      return axios.get('me/send-confirm-email/')
    },
  },
  modules: {
    personal,
    education,
    documents,
    career,
    other,
    passport,
  }
}
