import axios from '@/http/axios/axios'

export default {
  namespaced: true,
  actions: {
    GET_LIST(store, payload) {
      return axios.get(`dict/fms/?page=${payload.page}&page_size=${payload.page_size}&query=${payload.search}`)
    },
    GET_DATA(store, id) {
      return axios.get(`dict/fms/${id}/`)
    },
    POST_DATA(store, form) {
      return axios.post('dict/fms/', form)
    },
    PUT_DATA(store, payload) {
      return axios.put(`dict/fms/${payload.id}/`, payload.form)
    },
    DELETE_DATA(store, id) {
      return axios.delete(`dict/fms/${id}/`)
    },
  }
}
