import axios from '@/http/axios/axios'
import users from './users'
import org from './org'
import fms from './fms'

export default {
  namespaced: true,
  state: {
    sysInfo: {},
  },
  mutations: {
    setSysInfo(state, payload) {
      state.sysInfo = payload
    },
  },
  actions: {
    GET_SYSTEM_INFO({ commit }) {
      return axios.get('system/info/')
        .then(response => { commit('setSysInfo', response.data) })
    },
  },
  modules: {
    users,
    org,
    fms,
  }
}
