import axios from '@/http/axios/axios'

export default {
  namespaced: true,
  actions: {
    GET_LIST() {
      return axios.get('me/doc/')
    },
    GET_DATA(state, id) {
      return axios.get(`me/doc/${id}/`)
    },
    POST_DATA(state, data) {
      return axios.post('me/doc/', data)
    },
    PATCH_DATA(state, payload) {
      return axios.patch(`me/doc/${payload.id}/`, payload.data)
    },
    DELETE_DOC(state, id) {
      return axios.delete(`me/doc/${id}/`)
    },
  }
}
