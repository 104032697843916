import axios from '@/http/axios/axios'
import { clearAuthTokens } from 'axios-jwt'
import router from '@/router'

export default {
  async login (phone, password) {
    return await axios.post('token/jwt/create/', {
      phone,
      password
    })
  },
  logout() {
    clearAuthTokens()
    localStorage.clear()
    router.push({ name: 'login' }).catch(() => {})
  }
}
