import axios from '@/http/axios/axios'

export default {
  namespaced: true,
  actions: {
    GET_ORGS(store, payload) {
      return axios.get(`dict/organization-address/?page=${payload.page}&page_size=${payload.page_size}&query=${payload.search}`)
    },
    GET_ORG(store, payload) {
      return axios.get(`dict/organization-address/${payload}/`)
    },
    POST_ORG(store, form) {
      return axios.post('dict/organization-address/', form)
    },
    PUT_ORG(store, payload) {
      return axios.put(`dict/organization-address/${payload.id}/`, payload.form)
    },
    DELETE_ORG(store, payload) {
      return axios.delete(`dict/organization-address/${payload}/`)
    },
  }
}
