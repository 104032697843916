import axios from '@/http/axios/axios'

export default {
  namespaced: true,
  actions: {
    GET_DATA() {
      return axios.get('me/profile/')
    },
    PATCH_DATA(state, payload) {
      return axios.patch('me/profile/', payload)
    },
  }
}
