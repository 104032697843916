<template>
  <div class="filters">
    <label
        v-for="filter of filters"
        :key="filter.value"
        class="filters__label">
      <input
          @change="$emit('change', $event.target.value)"
          type="radio"
          name="filter"
          :value="filter.value"
          :checked="filter.value === value"
      >
      <span class="filters__item">{{ filter.label }}</span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'AppFilters',
  props: {
    filters: {
      type: Array,
      required: true,
    },
    value: {
      type: String,
    }
  },
  model: {
    event: 'change',
    prop: 'value'
  }
}
</script>

<style scoped lang="sass">
@import "@/assets/common/index.scss"

.filters
  display: flex
  flex-shrink: 0
  overflow: hidden
  border: 1px solid #c4d8ec
  border-radius: 7px

  input[type="radio"]
    +visually-hidden

    &:checked + .filters__item
      background-color: $color-theme
      color: #fff
      pointer-events: none

.filters__label
  border-right: 1px solid #c4d8ec

  &:last-child
    border: none

.filters__item
  display: block
  padding: 10px 20px

  background-color: #f8fdff
  font-size: 16px
  cursor: pointer
  transition: background-color 0.3s, color 0.3s

  &:hover
    background-color: #49b1f5
    color: #fff
</style>
