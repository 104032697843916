import axios from 'axios'

const baseURL = process.env.VUE_APP_MKP_PUBLIC_URL
// const token = localStorage.getItem('accessToken')
//
// if (token) {
//   axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
// }

export default axios.create({
  baseURL
})
