import axios from '@/http/axios/axios'

export default {
  namespaced: true,
  actions: {
    GET_DATA() {
      return axios.get('me/profile/passport/')
    },
    PATCH_DATA(state, form) {
      return axios.patch('me/profile/passport/', form)
    },
  }
}
