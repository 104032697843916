import axios from '@/http/axios/axios'
import axiosMkpPublic from '@/http/axios/axios-mkp-public'

export default {
  namespaced: true,
  actions: {
    POST_LINK_CARD(state, number) {
      return axios.post('integration/mkp/link-card/', number)
    },
    POST_CHECK_CARD() {
      return axios.post('integration/mkp/my-card/')
    },
    POST_CHECK_PROFILE() {
      return axios.post('integration/mkp/check-profile/')
    },
    GET_DELIVERY_ADDRESS(state, data) {
      return axiosMkpPublic.get(`address/?q=${data.search}`)
    },
    POST_CARD_DATA(state, form) {
      return axios.post('integration/mkp/create-card/', form)
    },
    GET_GOOGLE_LINK() {
      return axios.get('integration/mkp/link-google/')
    },
    GET_APPLE_LINK() {
      return axios.get('integration/mkp/link-apple/')
    },
  }
}
