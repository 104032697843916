<template>
  <div class="checkbox checkbox--list">
    <label v-for="item in data" :key="item.id" class="checkbox__wrapper">
      <span class="checkbox__text">{{ item.name }}</span>
      <input
          :id="item.id"
          type="checkbox"
          :value="item.id"
          :checked="check(item.id)"
          @change="onCheckboxChanged($event)"
      />
      <span class="checkbox__checkmark"></span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'AppCheckboxList',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: Array,
    data: Array,
  },
  data() {
    return {
      arr: [],
    }
  },
  watch: {
    value(array) {
      if (array) this.arr = array
    }
  },
  methods: {
    check(checkbox) {
      if (this.$props.value) return this.$props.value.includes(checkbox)
      return false
    },
    onCheckboxChanged(event) {
      let currentValue = null

      if (!isNaN(parseInt(event.target.value, 10)))  currentValue = +event.target.value
      else  currentValue = event.target.value

      const index = this.arr.indexOf(currentValue)
      if (index < 0) this.arr.push(currentValue)
      else this.arr.splice(index, 1)
      this.$emit('change', this.arr)
    },
  }
}
</script>

<style scoped lang="sass">
@import "@/assets/common/index.scss"

.checkbox
  display: grid
  grid-auto-flow: column
  gap: 10px 25px
  justify-content: flex-start

  input
    +visually-hidden

    &:checked ~ .checkbox__checkmark
      border-color: $color-theme

      &:after
        content: ""

.checkbox--list
  padding-left: 0

.checkbox__wrapper
  position: relative
  display: flex
  align-items: center
  padding-left: 32px
  cursor: pointer
  user-select: none

.checkbox--many
  margin-right: 30px

  &:last-child
    margin-right: 0

.checkbox__checkmark
  display: flex
  align-items: center
  justify-content: center
  position: absolute
  top: 0
  left: 0
  width: 20px
  height: 20px
  background-color: #fff
  border: 1px solid rgba(56, 123, 191, 0.3)
  box-sizing: border-box
  border-radius: 3px

  &:after
    content: none
    width: 12px
    height: 12px
    background-color: $color-theme

.checkbox__text
  font-size: 16px
  font-weight: 400
  line-height: 20px
  color: $color-text-base

  a
    color: $color-theme
    text-decoration: none

    &:hover
      text-decoration: underline
</style>
