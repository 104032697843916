<template>
  <nav class="menu" :class="{ 'menu--hide': $store.state.sidebar && !show && !mobileShow, 'menu--show': show }">
    <div class="menu__item" v-for="item in menu" :key="item.id">
      <router-link
        :to="{ name: item.link }"
        class="menu__link"
        active-class="menu__link--active"
      >
        <img
          :src="require('@/assets/img/sidebar/' + item.icon)"
          :alt="item.link"
          class="menu__icon"
        />
        <div class="menu__text">{{ item.name }}</div>
      </router-link>
    </div>
    <!--TODO: обсудить и переделать-->
    <template v-if="is_login && is_admin">
      <div class="sidebar__line"></div>
      <div class="menu__item">
        <router-link :to="{ name: 'admin-users-list' }" class="menu__link" active-class="menu__link--active">
          <img class="menu__icon" src="@/assets/img/sidebar/users-icon.svg" alt="пользователи">
          <div class="menu__text">Пользователи</div>
        </router-link>
      </div>
      <div class="menu__item">
        <router-link :to="{ name: 'stat' }" class="menu__link" active-class="menu__link--active">
          <img class="menu__icon" src="@/assets/img/sidebar/stat-icon.svg" alt="статистика">
          <div class="menu__text">Статистика</div>
        </router-link>
      </div>
      <div class="menu__item">
        <router-link :to="{ name: 'org' }" class="menu__link" active-class="menu__link--active">
          <img class="menu__icon" src="@/assets/img/sidebar/org.svg" alt="организации">
          <div class="menu__text">Организации</div>
        </router-link>
      </div>
      <div class="menu__item">
        <router-link :to="{ name: 'fms' }" class="menu__link" active-class="menu__link--active">
          <img class="menu__icon" src="@/assets/img/sidebar/guide.svg" alt="фмс">
          <div class="menu__text">ФМС</div>
        </router-link>
      </div>
    </template>
  </nav>
</template>

<script>
export default {
  name: 'Menu',
  props: ['show', 'mobileShow'],
  data () {
    return {
      menu: [
        {
          id: 1,
          name: 'Мой профиль',
          link: 'profile',
          icon: 'user-icon.svg',
        },
        {
          id: 2,
          name: 'Команды',
          link: 'teams',
          icon: 'users-icon.svg',
        },
        {
          id: 3,
          name: 'Мероприятия',
          link: 'events',
          icon: 'events-icon.svg',
        },
        {
          id: 4,
          name: 'Мои заявки',
          link: 'my-apps',
          icon: 'file-text-icon.svg',
        },
        // {
        //   id: 5,
        //   name: 'Карта МКП',
        //   link: 'mkp-card',
        //   icon: 'credit-card-icon.svg',
        // },
        // {
        //   id: 6,
        //   name: 'Членский билет',
        //   link: 'membership',
        //   icon: 'leaf-icon.svg',
        // },
      ],
    }
  },
  computed: {
    is_login() {
      return this.$store.state.auth.isUserLoggedIn()
    },
    is_admin() {
      return this.$store.state.meProfile.user_permissions.is_admin
    },
  }
}
</script>

<style lang="sass">
@import "@/assets/common/index.scss"

.menu
  padding-top: 10px

  .sidebar__line
    margin: 10px auto

.menu--hide
  .menu__text
    display: none

  .menu__arrow
    display: none

    &.is-show
      display: none

  .menu__submenu.is-show
    max-height: 0

.menu--show
  .menu__text
    display: block

  .menu__arrow
    display: block

  .menu__submenu
    max-height: 150px

    &:not(.is-show)
      max-height: 0

.menu__item
  position: relative

.menu__link
  display: flex
  align-items: center
  padding: 11px 32px
  border-left: 3px solid transparent
  color: #323f4b
  text-decoration: none
  transition: color 0.3s

  &:hover
    color: $color-theme

.menu__toggler
  display: flex
  align-items: center
  padding: 11px 32px
  border-left: 3px solid transparent
  color: #323f4b
  text-decoration: none
  cursor: pointer
  transition: color 0.3s

  &:hover
    color: $color-theme

  &.is-show
    .menu__arrow
      transform: rotate(180deg)

.menu__link--active
  border-left-color: $color-theme
  background-color: #f1f8fe

  .menu__text
    color: $color-theme
    font-weight: 600

.menu__icon
  margin-right: 18px

.menu__text
  font-size: 16px
  white-space: nowrap

.menu__submenu
  max-height: 0
  padding-left: 95px
  overflow: hidden
  transition: max-height 0.3s

  &.is-show
    max-height: 165px

  li
    position: relative
    margin-bottom: 10px

    &::before
      content: ''
      position: absolute
      top: 8px
      left: -16px
      width: 7px
      height: 7px
      border-radius: 50%
      background-color: $color-theme

.menu__subitem
  font-size: 16px
  color: $color-text-base
  text-decoration: none
  cursor: pointer
  transition: color 0.3s

  &.active
    color: $color-theme

  &:hover
    color: $color-theme

.menu__subitem--active
  color: $color-theme

.menu__arrow
  position: absolute
  top: 0
  right: 0
  width: 52px
  height: 52px
  background-image: url("~@/assets/img/sidebar/arrow-bottom.svg")
  background-repeat: no-repeat
  background-position: center
  background-size: 11px
  pointer-events: none
  transition: transform 0.3s

</style>
