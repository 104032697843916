import axios from '@/http/axios/axios'

export default {
  namespaced: true,
  actions: {
    GET_ORG_LIST() {
      return axios.get('me/edu/org/')
    },
    GET_ORG_DATA(state, id) {
      return axios.get(`me/edu/org/${id}`)
    },
    POST_ORG_DATA(state, payload) {
      return axios.post('me/edu/org/', payload)
    },
    PATCH_ORG_DATA(state, payload) {
      return axios.patch(`me/edu/org/${payload.id}/`, payload.data)
    },
    DELETE_ORG(state, id) {
      return axios.delete(`me/edu/org/${id}/`)
    },
    GET_LANG_LIST() {
      return axios.get('me/edu/lang/')
    },
    GET_LANG_DATA(state, id) {
      return axios.get(`me/edu/lang/${id}/`)
    },
    POST_LANG_DATA(state, payload) {
      return axios.post('me/edu/lang/', payload)
    },
    PUT_LANG_DATA(state, payload) {
      return axios.put(`me/edu/lang/${payload.id}/`, payload.data)
    },
    DELETE_LANG(state, id) {
      return axios.delete(`me/edu/lang/${id}/`)
    },
  }
}
