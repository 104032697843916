import axios from '@/http/axios/axios'

export default {
  namespaced: true,
  actions: {
    GET_LIST() {
      return axios.get('me/career/')
    },
    GET_DATA(state, id) {
      return axios.get(`me/career/${id}/`)
    },
    POST_DATA(state, data) {
      return axios.post('me/career/', data)
    },
    PATCH_DATA(state, payload) {
      return axios.patch(`me/career/${payload.id}/`, payload.data)
    },
    DELETE_CAREER(state, id) {
      return axios.delete(`me/career/${id}/`)
    },
  }
}
