<template>
  <label class="switcher" :class="{ 'switcher--active': value }">
    <input
      :checked="value"
      @change="$emit('change', $event.target.checked)"
      class="switcher__input"
      type="checkbox"
    >
    <span class="switcher__view">
      <span class="switcher__body"></span>
      <span class="switcher__btn"></span>
    </span>
    <span class="switcher__value">{{ value ? active_text : inactive_text }}</span>
  </label>
</template>

<script>
export default {
  name: 'AppSwitcher',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    active_text: {
      type: String,
      default: 'Вкл'
    },
    inactive_text: {
      type: String,
      default: 'Выкл'
    },
  },
}
</script>

<style lang="sass" scoped>
@import "@/assets/common/index.scss"

.switcher
  position: relative
  display: flex
  align-items: center
  gap: 10px

.switcher--active
  .switcher__body
    border-color: $color-theme
    background-color: rgba($color-theme, 0.8)

  .switcher__btn
    left: 33px
    border-color: $color-theme

  .switcher__value
    color: #38bf4e

.switcher__input
  +visually-hidden

.switcher__view
  display: block
  width: 66px
  height: 34px
  cursor: pointer

.switcher__body
  display: block
  width: 100%
  height: 100%
  background-color: #f4f4f4
  border-radius: 20px
  overflow: hidden
  border: 1px solid #e5e5e5
  transition: background-color 0.3s, border-color 0.3s

.switcher__btn
  position: absolute
  top: 50%
  left: 2px
  width: 31px
  height: 31px
  background-color: #fff
  border: 1px solid #e5e5e5
  border-radius: 50%
  transform: translateY(-50%)
  transition: background-color 0.3s, left 0.3s

.switcher__value
  color: $color-error

</style>