import axios from '@/http/axios/axios'

export default {
  namespaced: true,
  actions: {
    GET_DATA(state, payload) {
      return axios.get(`admin/events/${payload.event_id}/shifts/${payload.shift_id}/applications/${payload.app_id}/`)
    },
    PATCH_DATA(state, payload) {
      return axios.patch(`admin/events/${payload.event_id}/shifts/${payload.shift_id}/applications/${payload.app_id}/`, payload.data)
    },
  }
}
