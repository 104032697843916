import axios from '@/http/axios/axios'

export default {
  namespaced: true,
  actions: {
    GET_LIST(store, { page, page_size, search }) {
      return axios.get(`admin/user/?query=${search}&page=${page}&page_size=${page_size}`)
    },
    GET_DATA(store, id) {
      return axios.get(`admin/user/${id}/`)
    },
    PATCH_DATA(store, payload) {
      return axios.patch(`admin/user/${payload.id}/`, payload.data)
    },
    GET_GROUPS() {
      return axios.get('admin/user/groups/')
    },
  }
}
