import Vue from 'vue'
import Vuelidate from 'vuelidate'
import vSelect from 'vue-select'
import VueClip from 'vue-clip'
import VModal from 'vue-js-modal'
import Paginate from 'vuejs-paginate'
import VueTables from 'vue-tables-2'
import Notifications from 'vue-notification'
const VueInputMask = require('vue-inputmask').default
import VTooltip from 'v-tooltip'

import DefaultLayout from './layouts/Default'
import WelcomeLayout from './layouts/Welcome'
import PublicLayout from './layouts/Public'

Vue.component('default', DefaultLayout)
Vue.component('welcome', WelcomeLayout)
Vue.component('public', PublicLayout)
Vue.component('v-select', vSelect)
Vue.component('paginate', Paginate)
Vue.component('v-popover', VTooltip.VPopover)

Vue.directive('tooltip', VTooltip.VTooltip)
Vue.directive('close-popover', VTooltip.VClosePopover)

Vue.use(VueClip)
Vue.use(VModal)
Vue.use(VueTables.ClientTable)
Vue.use(Notifications)
Vue.use(Vuelidate)
Vue.use(VueInputMask)
Vue.use(VTooltip)