import axios from '@/http/axios/axios'

export default {
  namespaced: true,
  actions: {
    GET_DATA(state, id) {
      return axios.get(`me/events-shift/${id}/`)
    },
    POST_DATA(state, payload) {
      return axios.post(`me/events-application/${payload.dir}/`, payload.my_form)
    },
    GET_LIST(state, payload) {
      return axios.get(
        `admin/events/${payload.event_id}/shifts/${payload.shift_id}/applications/?page=${payload.page}
        &page_size=${payload.page_size}&status=${payload.status}&search=${payload.search}`
      )
    },
    GET_COUNTS(state, payload) {
      return axios.get(`admin/events/${payload.event_id}/shifts/${payload.shift_id}/applications/counts`)
    },
    GET_MY_LIST(state, page) {
      return axios.get(`me/events-applications/?page=${page}`)
    },
    GET_MY_APP(state, id) {
      return axios.get(`me/events-applications/${id}/`)
    },
    PATCH_MY_APP(state, payload) {
      return axios.patch(`me/events-application/${payload.shift_id}/${payload.id}/`, payload.my_form)
    },
    DELETE_MY_APP(state, id) {
      return axios.delete(`me/events-applications/${id}/`)
    },
    GET_EXPORT(store, payload) {
      return axios.get(`admin/events/${payload.id}/shifts/${payload.dir}/applications/export`, { responseType: 'blob' })
    }
  },
}
